import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
//
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import SideNavbar from "./components/Navbar/SideNavbar";
import Navbar from "./components/Navbar/Navbar";
import Trader from "./pages/Trader";
import Manufacturer from "./pages/Manufacturer";
import TransportCompany from "./pages/TransportCompany";
import LorryBroker from "./pages/LorryBroker";
import Suraksha from "./pages/Suraksha";
import Parivaar from "./pages/Parivaar";
import User from "./components/User/User";
import LoginPage from "./components/Login/LoginPage";
import Lorry from "./pages/Lorry";
import ChangeRouteOfLorryBroker from "./pages/ChangeRouteOfLorryBroker";
const Layout = () => {
  const location = useLocation();
  const shouldShowNavbar = location.pathname !== "/";
  const pageContentClass = !shouldShowNavbar
    ? "page-content page-content-Login"
    : "page-content";

  return (
    <div className="app-container">
      {shouldShowNavbar && <Navbar />}
      {shouldShowNavbar && <SideNavbar />}
      <div className={pageContentClass}>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/trader" element={<Trader />} />
          <Route path="/manufacturer" element={<Manufacturer />} />
          <Route path="/transportCompany" element={<TransportCompany />} />
          <Route path="/lorryBroker" element={<LorryBroker />} />
          <Route path="/suraksha" element={<Suraksha />} />
          <Route path="/parivaar" element={<Parivaar />} />
          <Route path="/user/:firstName/:uniquecode" element={<User />} />
          <Route path="/lorry" element={<Lorry />} />
          <Route path="/ChangeRouteOfLorryBroker" element={<ChangeRouteOfLorryBroker />} />
        </Routes>
      </div>
      <ToastContainer />
    </div>
  );
};

const App = () => (
  <Router>
    <Layout />
  </Router>
);

export default App;
