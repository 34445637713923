import React from 'react'
import Stats from '../components/Stats/Stats'
import Table from '../components/Table/Table'

const TransportCompany = () => {
  return (
    <>
      <Stats />
      <Table />
    </>
  )
}

export default TransportCompany
