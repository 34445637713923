import React, { useEffect, useState } from "react";
import "../../styles/components/user/User.css";
import {
  clearSingleUser,
  fetchSingleUserDriverSupplier,
  fetchSingleUserLogistic,
  fetchSingleUserParivaar,
  fetchSingleUserSuraksha,
  paymentByPassDriverLogisticApi,
  paymentByPassDriverSupplierApi,
  paymentByPassSurakshaApi,
  singleUser,
  selectloading,
  kycBlockSurakshaApi,
  kycBlockDriverSupplierApi,
  kycBlockLogisticApi,
  kycBlockParivaarApi,
  blockUserAPiSurksha,
  blockUserAPiLogistic,
  blockUserAPiParivaar,
  blockUserAPiBussiness,
} from "../../store/getUserData/allUserData";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ImagePopUp from "./ImagePopUp";
import {
  selectSelectedNavItem,
  setNumber,
} from "../../store/selectCategory/selectCategory";
import Loader from "../Loader/Loader";
import ConfirmPopUp from "./ConfirmPopUp";

const User = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uniquecode } = useParams();
  const loading = useSelector(selectloading);

  const selectedNavItem = useSelector(selectSelectedNavItem);
  const singleUserData = useSelector(singleUser);





  const [showPopUp, setShowPopUp] = useState(false);
  const [showPopUp1, setShowPopUp1] = useState(false);
  const [imgLink, setImgLink] = useState("");
  const [functionCall, setFunctionCall] = useState(null);
  const [lorrypopUp, setLorrypopUp] = useState(false)

  console.log(singleUserData, "singleUser")

  const covertTime = (givenTimeInUTC) => {
    // Create a Date object using the current date with the given UTC time
    const [hours, minutes, seconds] = givenTimeInUTC.split(":").map(Number);
    const now = new Date();
    now.setUTCHours(hours, minutes, seconds, 0);

    const istTime = new Date(now.getTime());

    // Format the IST time in AM/PM format
    let istHours = istTime.getHours();
    const istMinutes = istTime.getMinutes().toString().padStart(2, "0");
    const istSeconds = istTime.getSeconds().toString().padStart(2, "0");
    const period = istHours >= 12 ? "PM" : "AM";

    // Convert 24-hour time to 12-hour time
    istHours = istHours % 12 || 12;

    const istTimeString = `${istHours}:${istMinutes}:${istSeconds} ${period}`;
    return istTimeString;
  };

  useEffect(() => {
    if (selectedNavItem === "suraksha") {
      dispatch(fetchSingleUserSuraksha());
    }
    if (selectedNavItem === "parivaar") {
      dispatch(fetchSingleUserParivaar());
    }
    if (selectedNavItem === "lorryBroker") {
      dispatch(fetchSingleUserDriverSupplier());
    }
    if (
      selectedNavItem === "trader" ||
      selectedNavItem === "manufacturer" ||
      selectedNavItem === "transportCompany"
    ) {
      dispatch(fetchSingleUserLogistic());
    }
  }, [uniquecode]);

  // for pop up open
  const handleOpenPopUp = (img) => {
    setShowPopUp(true);
    setImgLink(img);
  };

  const handleByPassPayment = (number) => {
    dispatch(setNumber(number));
    const handleByPassPayment1 = () => {
      if (selectedNavItem === "suraksha") {
        dispatch(paymentByPassSurakshaApi());
      }
      if (selectedNavItem === "lorryBroker") {
        dispatch(paymentByPassDriverSupplierApi());
      }
      if (
        selectedNavItem === "trader" ||
        selectedNavItem === "manufacturer" ||
        selectedNavItem === "transportCompany"
      ) {
        dispatch(paymentByPassDriverLogisticApi());
      }
      setShowPopUp1(false);
    };

    setFunctionCall(() => handleByPassPayment1);
    setShowPopUp1(true);
  };

  // handler for kyc byPass
  const handleKycByPass = (number) => {
    dispatch(setNumber(number));
    const handleKycByPass1 = () => {
      if (selectedNavItem === "suraksha") {
        dispatch(kycBlockSurakshaApi());
      }

      if (selectedNavItem === "lorryBroker") {
        dispatch(kycBlockDriverSupplierApi());
      }

      if (selectedNavItem === "parivaar") {
        dispatch(kycBlockParivaarApi());
      }
      if (
        selectedNavItem === "trader" ||
        selectedNavItem === "manufacturer" ||
        selectedNavItem === "transportCompany"
      ) {
        dispatch(kycBlockLogisticApi());
      }
      setShowPopUp1(false);
    };

    setFunctionCall(() => handleKycByPass1);
    setShowPopUp1(true);
  };

  // handler Block User
  const handleBlockUser = () => {
    const handleKycByPass1 = () => {
      if (selectedNavItem === "suraksha") {
        dispatch(blockUserAPiSurksha());
      }

      if (selectedNavItem === "lorryBroker") {
        dispatch(blockUserAPiBussiness());
      }

      if (selectedNavItem === "parivaar") {
        dispatch(blockUserAPiParivaar());
      }
      if (
        selectedNavItem === "trader" ||
        selectedNavItem === "manufacturer" ||
        selectedNavItem === "transportCompany"
      ) {
        dispatch(blockUserAPiLogistic());
      }
      setShowPopUp1(false);
    };

    setFunctionCall(() => handleKycByPass1);
    setShowPopUp1(true);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="userDetailsPage">
        <div className="ProfileSection">
          <div>
            <div className="popUpBackBtn">
              <button
                onClick={() => {
                  navigate(-1);
                  dispatch(clearSingleUser());
                }}
              >
                Back
              </button>
            </div>
            <div className="UserImage">
              <img src={singleUserData?.User?.profileImg} alt="" />
            </div>
            <h3>{`${singleUserData?.User?.firstName}  ${singleUserData?.User?.lastName}`}</h3>

            <div className="userVarStatus">
              <h4>
                RC Verify:{" "}
                <span>{singleUserData?.User?.rcVerifyOnlyShowCount}</span>{" "}
              </h4>
              <h4>
                GST Verify:{" "}
                <span>{singleUserData?.User?.rcVerifyOnlyShowCount}</span>
              </h4>
              <h4>
                License Search:{" "}
                <span>{singleUserData?.User?.licenseSearchCount}</span>
              </h4>
            </div>
          </div>

          <div className="paymentStatus">
            <h4>payment status</h4>
            <h6>
              {singleUserData?.User?.payment?.status === true
                ? "Paid"
                : singleUserData?.User?.payment?.status === true
                  ? singleUserData?.User?.payment?.bypass === false
                    ? "Not Paid"
                    : "Bypass"
                  : "Not Paid"}
            </h6>
            {singleUserData?.User?.payment?.status === true && (
              <>
                {" "}
                <h4>
                  {singleUserData?.User?.payment?.createdAt
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("-")}
                </h4>
                <h4>
                  {covertTime(
                    singleUserData?.User?.payment?.createdAt
                      .split("T")[1]
                      .split(".")[0]
                  )}
                </h4>
              </>
            )}
          </div>
        </div>

        <div className="detailSection">
          <div className="detailSectionButtons">
            {!(selectedNavItem === "parivaar") && (
              <button
                onClick={() =>
                  handleByPassPayment(
                    singleUserData?.User?.number
                      ? singleUserData?.User?.number
                      : singleUserData?.User?.mobileNumber
                  )
                }
              >
                Payment Bypass
              </button>
            )}
            {!singleUserData?.User?.verificationStatus && (
              <button
                onClick={() =>
                  handleKycByPass(
                    singleUserData?.User?.number
                      ? singleUserData?.User?.number
                      : singleUserData?.User?.mobileNumber
                  )
                }
              >
                KYC Bypass
              </button>
            )}
            {!singleUserData?.User?.blocked && (
              <button
                onClick={() => {
                  handleBlockUser();
                }}
              >
                Block User
              </button>
            )}
          </div>

          <div className="typeHeader">

            {/* <h4>
                        UserType:{" "}
                        <span className="">{singleUserData?.User?.type}</span>{" "}
                    </h4>
                    <h4>
                        Type: <span>{singleUserData?.User?.type}</span>
                    </h4> */}
            <h4>
              Unique ID:{" "}
              <span>
                {singleUserData?.User?.uniquecode
                  ? singleUserData?.User?.uniquecode
                  : singleUserData?.User?.uniqueCode}
              </span>
            </h4>
            <h4>
              Verification Status:{" "}
              <span>
                {singleUserData?.User?.blocked === true
                  ? "Blocked User"
                  : singleUserData?.User?.verificationStatus === false &&
                    singleUserData?.User?.RejectionStatus === false
                    ? "Under Verification"
                    : singleUserData?.User?.verificationStatus === true
                      ? "Verified"
                      : singleUserData?.User?.verificationStatus === false &&
                        singleUserData?.User?.RejectionStatus === true
                        ? "Rejected"
                        : ""}
              </span>
            </h4>
            <h4>
              Email: <span>{singleUserData?.User?.email}</span>
            </h4>
            <h4>
              {" "}
              <span></span>
            </h4>
            <h4>
              Phone No.:{" "}
              <span>
                {singleUserData?.User?.number
                  ? singleUserData?.User?.number
                  : singleUserData?.User?.mobileNumber}
              </span>
            </h4>

            <h4>Number Of Lorry :
              <span>{singleUserData.allTruckOfUser?.length < 10 ? ` 0${singleUserData.allTruckOfUser?.length}` : singleUserData.allTruckOfUser?.length}</span>

            </h4>
            <button className="lorryNumberCheck" onClick={() => setLorrypopUp(true)}>View</button>
          </div>

          <div className="ducumentSection1">
            <h4>Document Section:</h4>

            <div className="ducumentSection1_content">
              {(singleUserData?.User?.gstno || singleUserData?.User?.gst) && (
                <div className="ducumentSection1_div">
                  <div>
                    <h5>GST No:</h5>{" "}
                    <p>
                      {singleUserData?.User?.gstno
                        ? singleUserData?.User?.gstno
                        : singleUserData?.User?.gst}
                    </p>
                  </div>
                  {singleUserData?.User?.gstImage && (
                    <button
                      onClick={() =>
                        handleOpenPopUp(singleUserData?.User?.gstImage)
                      }
                    >
                      view
                    </button>
                  )}
                </div>
              )}


              {singleUserData?.User?.aadharNumber && (
                <div className="ducumentSection1_div">
                  <div>
                    <h5>Aadhar No:</h5>{" "}
                    <p>{singleUserData?.User?.aadharNumber}</p>
                  </div>
                  {singleUserData?.User?.aadharFrontImg && (
                    <button
                      onClick={() =>
                        handleOpenPopUp(
                          [
                            singleUserData?.User?.aadharFrontImg,
                            singleUserData?.User?.aadharBackImg,
                          ].filter(Boolean)
                        )
                      }
                    >
                      view
                    </button>
                  )}
                </div>
              )}

              {(singleUserData?.User?.panCardNumber ||
                singleUserData?.User?.pan) && (
                  <div className="ducumentSection1_div">
                    <div>
                      <h5>Pan No:</h5>{" "}
                      <p>
                        {singleUserData?.User?.panCardNumber
                          ? singleUserData?.User?.panCardNumber
                          : singleUserData?.User?.pan}
                      </p>
                    </div>
                    {(singleUserData?.User?.panCardImg ||
                      singleUserData?.User?.panImage) && (
                        <button
                          onClick={() =>
                            handleOpenPopUp(
                              singleUserData?.User?.panCardImg
                                ? singleUserData?.User?.panCardImg
                                : singleUserData?.User?.panImage
                            )
                          }
                        >
                          view
                        </button>
                      )}
                  </div>
                )}

              {singleUserData?.User?.licenseNumber && (
                <div className="ducumentSection1_div">
                  <div>
                    <h5>License No:</h5>{" "}
                    <p>{singleUserData?.User?.licenseNumber}</p>
                  </div>
                  <button>view</button>
                </div>
              )}
            </div>
          </div>

          <div className="ducumentSection">
            {singleUserData?.User?.state && (
              <>
                <h4>Address Section:</h4>

                <div className="typeHeader">
                  {singleUserData?.User?.addressLine1 && (
                    <>
                      {" "}
                      <h4>
                        Address Line 1 :{" "}
                        <span className="">
                          {singleUserData?.User?.addressLine1}
                        </span>{" "}
                      </h4>{" "}
                    </>
                  )}

                  {(singleUserData?.User?.addressLine2 ||
                    singleUserData?.User?.streetAddress) && (
                      <>
                        {" "}
                        <h4>
                          Address Line 2 :{" "}
                          <span>
                            {singleUserData?.User?.addressLine2
                              ? singleUserData?.User?.addressLine2
                              : singleUserData?.User?.streetAddress}
                          </span>
                        </h4>
                      </>
                    )}

                  <h4>
                    City : <span>{singleUserData?.User?.city}</span>
                  </h4>
                  <h4>
                    Pin Code :{" "}
                    <span>
                      {singleUserData?.User?.pinCode
                        ? singleUserData?.User?.pinCode
                        : singleUserData?.User?.pincode}
                    </span>
                  </h4>
                  <h4>
                    State : <span>{singleUserData?.User?.state}</span>
                  </h4>
                  {singleUserData?.User?.states && (
                    <>
                      {" "}
                      <h4>
                        States :{" "}
                        <span>{singleUserData?.User?.states.join(", ")}</span>
                      </h4>
                    </>
                  )}
                </div>
              </>
            )}

            {singleUserData?.User?.originAddressLine1 &&
              singleUserData?.User?.originState && (
                <>
                  {" "}
                  <h5> Origin Address</h5>
                  <div className="typeHeader">
                    <h4>
                      Origin Address Line 1 :{" "}
                      <span className="">
                        {" "}
                        {singleUserData?.User?.originAddressLine1}
                      </span>{" "}
                    </h4>
                    <h4>
                      Origin Address Line2 :{" "}
                      <span> {singleUserData?.User?.originAddressLine2}</span>
                    </h4>
                    <h4>
                      Origin City :{" "}
                      <span> {singleUserData?.User?.originCity}</span>
                    </h4>
                    <h4>
                      Origin Pin Code :{" "}
                      <span> {singleUserData?.User?.originPincode}</span>
                    </h4>
                    <h4>
                      Origin State :{" "}
                      <span> {singleUserData?.User?.originState}</span>
                    </h4>
                  </div>
                </>
              )}
          </div>
        </div>



        {/* Lorry Number Details PopUp */}

        {
          lorrypopUp && (<div className="LorryDetailsPopUp_main_container">
            <div className="LorryDetailsPopUp_container">
              <div>
                <h3 onClick={() => setLorrypopUp(false)}>X</h3>
              </div>
              <ul>
                {
                  singleUserData?.allTruckOfUser.map((item, i) => {
                    return (
                      <li style={{ color: item.approve ? "green " : "var(--primaryColor", display: "flex", alignItems: "center" }}>
                        <span style={{ color: "black", fontWeight: "bold" }}>{i + 1}  &#41; </span> <p style={{ marginLeft: "2%", fontWeight:"bold" }}>{item?.lorryNumber}</p>
                      </li>
                    );
                  })
                }


              </ul>
            </div>
          </div>
          )
        }



        {showPopUp && (
          <ImagePopUp onClose={() => setShowPopUp(false)} img={imgLink} />
        )}
        {showPopUp1 && (
          // <ImagePopUp onClose={() => setShowPopUp(false)} img={imgLink} />
          <ConfirmPopUp
            onClose={() => setShowPopUp1(false)}
            functionCall={functionCall}
          />
        )}
      </div>
    </>
  );
};

export default User;
