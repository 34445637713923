import React from "react";
import LorryPopUp from "../components/Lorry/LorryPopUp";
import { useState } from "react";
import "../styles/components/lorry/Lorry.css";
import { useGetTruckDataQuery } from "../store/getLorryData/LorryDataSlice";
import LorryPagination from "../components/Lorry/LorryPagination";
import ViewApplicationPopUp from "../components/Lorry/ViewApplicationPopUp";

const tableStyle = {
  width: "100%",
  textAlign: "center",
  borderCollapse: "collapse",
};

const Lorry = () => {
  const [page, setPage] = useState(1);
  const [vievPopUp, setViewPopUp] = useState(false);
  const [openPopUp, setOpenPopUp] = useState(false);
  const [viewDetails, setViewDetails] = useState([]);
  const [selectedTruck, setSelectedTruck] = useState(null);
  const closePopup = () => {
    setOpenPopUp(false);
  };
  const handleCloseView = () => {
    setViewPopUp(false);
  };
  const handleClick = (isApprove, truckId) => {
    if (isApprove !== true) {
      setOpenPopUp(true);
      setSelectedTruck(truckId);
    }
  };
  const { data, isLoading, isSuccess, isError } = useGetTruckDataQuery({
    page,
  });
  console.log("xxxxxxxxxxxx", data);
  if (isError) {
    return <h1>Error 404</h1>;
  }
  if (isLoading) {
    return <h4>Loading...</h4>;
  }
  const handleOpenView = (item) => {
    setViewDetails([item]);
    setViewPopUp(true);
  };
  const totalPages = data?.totalPages || 1;
  const displayedData = data?.truckDetails || [];
  return (
    <>
      <div className="lorryPage_Container">
        <div className="SearchDiv_lorryDiv">
          <input
            type="text"
            name=""
            className="SearchInpLorry_dataTable"
            id=""
            placeholder="search by Lorry No."
          />
        </div>
        <div className="table_container_lorry">
          <table className="TableDiv_lorryDiv" style={tableStyle}>
            <thead className="TableDiv_lorryDiv_heading">
              <tr className="truckLorryData_row">
                <th className="Lorry_Heading_Table">Lorry No.</th>
                <th className="Lorry_Heading_Table">Truck Manufacturer</th>
                <th className="Lorry_Heading_Table">Truck Type</th>
                <th className="Lorry_Heading_Table">Truck Capability</th>
                <th className="Lorry_Heading_Table">Body Type</th>
                <th className="Lorry_Heading_Table">Truck Size</th>
                <th className="Lorry_Heading_Table">Approve status</th>
                <th className="Lorry_Heading_Table">Application</th>
              </tr>
            </thead>
            <tbody className="tbody_truckLorryData_row">
              {isSuccess &&
                displayedData?.map((truck, index) => (
                  <tr
                    key={index}
                    className={`truckLorryData_row ${
                      index % 2 !== 0 ? "evenData_Table" : "oddData_Table"
                    }`}
                    style={{ height: "2vw" }}
                  >
                    <td>{truck.lorryNumber}</td>
                    <td>{truck.truckManufacturer}</td>
                    <td>{truck.truckType}</td>
                    <td>{truck.truckCapability}</td>
                    <td>{truck.bodyType}</td>
                    <td>{truck.truckSize}</td>

                    <td
                      style={{
                        color: truck.approve === true ? "green" : "red",
                        cursor: truck.approve !== true ? "pointer" : "default",
                      }}
                      onClick={() => handleClick(truck.approve, truck._id)}
                    >
                      {truck.approve === true ? "Approved" : "Not Approved"}
                    </td>
                    <td
                      className="viewLorry_Application"
                      onClick={() => handleOpenView(truck.addedBy)}
                    >
                      {"view"}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {openPopUp && <LorryPopUp truckId={selectedTruck} close={closePopup} />}
        {vievPopUp && (
          <ViewApplicationPopUp
            handleCloseView={handleCloseView}
            viewDetails={viewDetails}
          />
        )}
      </div>
      <LorryPagination page={page} setPage={setPage} totalPages={totalPages} />
    </>
  );
};

export default Lorry;
