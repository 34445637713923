import React from 'react'
import Table from '../components/Table/Table'
import Stats from '../components/Stats/Stats'

const Manufacturer = () => {
    return (
        <>
            <Stats />
            <Table />

        </>
    )
}

export default Manufacturer
