import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import '../../styles/components/ChangeRouteOfLorryBroker/ChangeRouteSelectDataPopUp.css';
import { useSetAddRouteMutation } from '../../store/GetChangeRouteLorryBrokerData/ChangeRouteData';
import { IoMdAdd } from 'react-icons/io';
import { indiaDistricts, indiaStates } from './IndiaStatedata';
import img1 from '../../Assets/image/Group 685.png';
import img2 from '../../Assets/image/Group 1098.png';
import img3 from '../../Assets/image/Group 3279.png';
import img4 from '../../Assets/image/Group 1556.png';
import wheel from '../../Assets/image/wheel.png';
import singleAxle from '../../Assets/image/Group 2508.png';
import multiAxle from '../../Assets/image/Group 2495.png';
import home from '../../Assets/image/home.png';
import office from '../../Assets/image/office.png';
import { toast } from 'react-toastify';
import Loader from '../Loader/Loader';



const ChangeRouteSelectDataPopUp = ({ onclose, num }) => {
    const [state, setState] = useState([{ state: "", district: [] }]);
    const [editdata, setEditData] = useState({
        bodyType: [],
        truckSize: [],
        wheel: [],
        axle: [],
        workingFrom: "",
        gstno: ""
    });
    const [addRoute, { isLoading, isError, isSuccess }] = useSetAddRouteMutation();

    const handleSubmit = () => {
        console.log(editdata.gstno);
        const data = {
            stateanddistrict: state,
            axle: editdata.axle,
            body: editdata.bodyType,
            length: editdata.truckSize,
            wheel: editdata.wheel,
            workingfrom: editdata.workingFrom,
            gstno: editdata.gstno
        };
        addRoute({ data, num })
        setEditData({
            bodyType: [],
            truckSize: [],
            wheel: [],
            axle: [],
            workingFrom: "",
            gstno: ""
        });
        setState([{ state: "", district: [] }]);
    };
    const handleSelectChangeInForm = (event, index) => {
        const { name, value } = event.target;
        setState(prevstate =>
            prevstate.map((form, i) =>
                i === index ? { ...form, [name]: value, district: [] } : form
            )
        );
    };

    const handleMultiSelectChangeInForm = (selectedOptions, field, index) => {
        setState(prevstate =>
            prevstate.map((form, i) =>
                i === index ? { ...form, [field]: selectedOptions.map(option => option.value) } : form
            )
        );
    };

    const handleCheckboxChange = (e) => {
        const { name, value } = e.target;
        setEditData(prevState => {
            const currentSelection = new Set(prevState[name]);
            if (currentSelection.has(value)) {
                currentSelection.delete(value);
            } else {
                currentSelection.add(value);
            }
            return {
                ...prevState,
                [name]: Array.from(currentSelection)
            };
        });
    };

    const handleSingleValueChange = (e) => {
        const { name, value } = e.target;
        setEditData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleButtonClick = () => {
        setState(prevstate => [
            ...prevstate,
            {
                state: "",
                district: []
            }
        ]);
    };

    const getDistrictOptions = (state) => {
        const districts = indiaDistricts[state] || [];
        return districts.map(district => ({ value: district, label: district }));
    };

    const bodytype = [
        {
            img: img1,
            name: "Trailer"
        },
        {
            img: img2,
            name: "Tanker"
        },
        {
            img: img3,
            name: "Dala Body"
        },
        {
            img: img4,
            name: "Container"
        }
    ];

    const wheelOptions = [
        {
            name: 6,
            img: wheel
        },
        {
            name: 8,
            img: wheel
        },
        {
            name: 10,
            img: wheel
        },
        {
            name: 12,
            img: wheel
        },
        {
            name: 14,
            img: wheel
        }
    ];

    const axleData = [
        {
            name: 'Single Axle',
            img: singleAxle
        },
        {
            name: 'Multi Axle',
            img: multiAxle
        }
    ];

    const workintType = [
        {
            name: "Home",
            img: home
        },
        {
            name: "Office",
            img: office
        }
    ];


    useEffect(() => {
        if (isSuccess) {
            onclose();
        } else if (isError) {
            toast.error('try again');
        } else if (isLoading) {
            <Loader />
        }
    })
    return (
        <>
            <div className='ChangeRouteSelectDataPopUp_main_container'>
                <div className='changeRouteSelectDataPopUp_hideBtn_box'>
                    <h2 onClick={onclose}>X</h2>
                </div>
                {/* State */}
                <div className='congeRouteSelectDataPopUp_select_main_container'>
                    <div>
                        {state.map((form, index) => (
                            <div key={index} className='changeRouteSelectDataPopUp_select_box'>
                                <div className='changeRouteSelectDataPopUp_select_selectState'>
                                    <select
                                        id={`state-${index}`}
                                        name="state"
                                        value={form.state}
                                        onChange={(e) => handleSelectChangeInForm(e, index)}
                                    >
                                        <option value="">Select State</option>
                                        {indiaStates.map(state => (
                                            <option key={state} value={state}>
                                                {state}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className='changeRouteSelectDataPopUp_select_selectDistrict'>
                                    <Select
                                        isMulti
                                        options={getDistrictOptions(form.state)}
                                        value={getDistrictOptions(form.state).filter(option => form.district.includes(option.value))}
                                        onChange={(selectedOptions) => handleMultiSelectChangeInForm(selectedOptions, 'district', index)}
                                        getOptionLabel={(option) => option.label}
                                        getOptionValue={(option) => option.value}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className='changeRouteSelectDataPopUp_checkbox_add_newbox_btn'>
                        <span onClick={handleButtonClick}><IoMdAdd /></span>
                    </div>
                </div>

                {/* Body Type */}
                <div className='changeRouteSelectDataPopUp_select_truck_Size'>
                    <h3>Body Type</h3>
                    <p>Select any one or two body types.</p>
                    <div className='changeRouteSelectDataPopUp_radio_main_box'>
                        {bodytype.map(({ img, name }) => (
                            <div key={name} className={`changeRouteSelectDataPopUp_radio ${editdata.bodyType.includes(name) ? 'selected-checkbox' : ''}`}>
                                <input
                                    type="checkbox"
                                    id={`bodyType-${name}`}
                                    name="bodyType"
                                    value={name}
                                    checked={editdata.bodyType.includes(name)}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor={`bodyType-${name}`} className='checkbox-label'>
                                    {name}
                                </label>
                                <img src={img} alt={name} className='checkbox-image' />
                            </div>
                        ))}
                    </div>
                </div>

                {/* Truck Size */}
                <div className='changeRouteSelectDataPopUp_select_truck_Size'>
                    <h3>Truck Size</h3>
                    <p>Select any one or two truck sizes.</p>
                    <div className='changeRouteSelectDataPopUp_radio_main_box'>
                        {['17-22 ft', '24-28 ft', '32-40 ft', '40+ ft'].map(size => (
                            <div key={size} className={`changeRouteSelectDataPopUp_radio ${editdata.truckSize.includes(size) ? 'selected-checkbox' : ''}`}>
                                <input
                                    type="checkbox"
                                    id={`truckSize-${size}`}
                                    name="truckSize"
                                    value={size}
                                    checked={editdata.truckSize.includes(size)}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor={`truckSize-${size}`} className='checkbox-label' style={{ fontWeight: "bold" }}>
                                    {size}
                                </label>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Wheels */}
                <div className='changeRouteSelectDataPopUp_select_truck_Size'>
                    <h3>No. of Wheels</h3>
                    <p>Select any one or two wheel sizes.</p>
                    <div className='changeRouteSelectDataPopUp_radio_main_box'>
                        {wheelOptions.map(({ img, name }) => (
                            <div key={name} className={`changeRouteSelectDataPopUp_radio ${editdata.wheel.includes(name.toString()) ? 'selected-checkbox' : ''}`}>
                                <input
                                    type="checkbox"
                                    id={`wheel-${name}`}
                                    name="wheel"
                                    value={name}
                                    checked={editdata.wheel.includes(name.toString())}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor={`wheel-${name}`} className='checkbox-label'>
                                    {name}
                                </label>
                                <img src={img} alt={`Wheel ${name}`} className='checkbox-image' />
                            </div>
                        ))}
                    </div>
                </div>

                {/* Axle */}
                <div className='changeRouteSelectDataPopUp_select_truck_Size'>
                    <h3>No. of Axle</h3>
                    <p>Select one or more axle types.</p>
                    <div className='changeRouteSelectDataPopUp_radio_main_box'>
                        {axleData.map(({ name, img }) => (
                            <div key={name} className={`changeRouteSelectDataPopUp_radio ${editdata.axle.includes(name.toLowerCase()) ? 'selected-checkbox' : ''}`}>
                                <input
                                    type="checkbox"
                                    id={`axle-${name.toLowerCase().replace(' ', '-')}`}
                                    name="axle"
                                    value={name.toLowerCase()}
                                    checked={editdata.axle.includes(name.toLowerCase())}
                                    onChange={handleCheckboxChange}
                                />
                                <label htmlFor={`axle-${name.toLowerCase().replace(' ', '-')}`} className='checkbox-label'>
                                    {name}
                                </label>
                                <img src={img} alt={name} className='changeRouteSelectDataPopUp_radio_img' />
                            </div>
                        ))}
                    </div>
                </div>

                {/* Working From */}
                <div className='changeRouteSelectDataPopUp_select_truck_Size'>
                    <h3>Working From</h3>
                    <p>Select any one type.</p>
                    <div className='changeRouteSelectDataPopUp_radio_main_box'>
                        {workintType.map(({ name, img }) => (
                            <div
                                key={name}
                                className={`changeRouteSelectDataPopUp_radio ${editdata.workingFrom === name ? 'selected-radio' : ''}`}
                            >
                                <input
                                    type="radio"
                                    id={`workingFrom-${name.toLowerCase()}`}
                                    name="workingFrom"
                                    value={name}
                                    checked={editdata.workingFrom === name}
                                    onChange={handleSingleValueChange}
                                />
                                <label htmlFor={`workingFrom-${name.toLowerCase()}`} className='radio-label'>
                                    {name}
                                </label>
                                <img src={img} alt={name} className='radio-image' />
                            </div>
                        ))}
                    </div>
                </div>

                <div className='changeRouteSelectDataPopUp_select_truck_Size'>
                    <h3>GST NUMBER (Optional)</h3>
                    <input
                        type="text"
                        placeholder='Enter GST Number'
                        value={editdata.gstno}
                        onChange={(e) => setEditData({ ...editdata, gstno: e.target.value })}
                        className='changeRouteSelectDataPopUp_gst_input'
                    />
                </div>

                <button onClick={handleSubmit}>Submit</button>
            </div>
        </>
    );
};

export default ChangeRouteSelectDataPopUp;
