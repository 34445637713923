import { useUpdateApiStatusMutation } from "../../store/getLorryData/LorryDataSlice";
import "../../styles/components/lorry/LorryPopUp.css";
import { RxCross2 } from "react-icons/rx";

const LorryPopUp = ({ close, truckId }) => {
  const [updateApprovalStatus] = useUpdateApiStatusMutation();

  const handleYesClick = async () => {
    try {
      await updateApprovalStatus(truckId).unwrap();

      alert("Approval status updated successfully!");
      close(); // Close the popup after successful update
    } catch (error) {
      console.error("Failed to update approval status:", error);
      alert("Failed to update approval status. Please try again.");
    }
  };

  return (
    <div className="LorryPopUp_container">
      <div className="Cut_Icon_cross_click">
        <RxCross2 onClick={close} className="LorryPopUp_CrossBtn" />
      </div>
      <div className="popUp_Content_Lorry">
        <h3>Are you sure?</h3>
        <div className="popUp_btns_lorryTruck">
          {/* The close function is called after handleYesClick for a successful update */}
          <button className="popUp_btns_button" onClick={handleYesClick}>
            Yes
          </button>
          <button className="popUp_btns_button" onClick={close}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default LorryPopUp;
