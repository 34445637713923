import pkg from "../../../package.json";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getCookie } from "../Cookies";

const ChangeRouteData = createApi({
  reducerPath: "ChangeRouteData",
  baseQuery: fetchBaseQuery({
    baseUrl: pkg.baseUrl,
    prepareHeaders: (headers) => {
      const token = getCookie("authToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  tagTypes: ["ChangeRoute"],
  endpoints: (builder) => ({
    getChangeRouteData: builder.query({
      query: (num) => ({
        url: `/adminpanel/getRoute/${num}`,
        method: "GET",
      }),
      providesTags: (num) => [{ type: "ChangeRoute", id: num }],
    }),

    setAddRoute: builder.mutation({
      query: ({ data, num }) => ({
        url: `/adminpanel/addRoute/${num}`,
        method: "PUT",
        body: JSON.stringify(data),
      }),
      invalidatesTags: [{ type: "ChangeRoute" }],
    }),
  }),
});

export const { useGetChangeRouteDataQuery, useSetAddRouteMutation } =
  ChangeRouteData;
export default ChangeRouteData;
