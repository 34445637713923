import React from "react";
import "../../styles/components/lorry/LorryPagination.css";

const LorryPagination = ({ page, setPage, totalPages }) => {
  const handleClickNext = () => {
    if (page < totalPages) setPage(page + 1);
  };
  const handleClickprevious = () => {
    if (page > 1) setPage(page - 1);
  };
  const handleFirstPage = () => {
    setPage(1);
  };
  const handleLastPage = () => {
    setPage(totalPages);
  };

  return (
    <div className="PAGINATION_Container">
      <div className="button_division_navigate" onClick={handleFirstPage}>
        {"<<"}
      </div>
      <div className="button_division" onClick={handleClickprevious}>
        {"<"}
      </div>
      <div className="button_division_Page_number">{page}</div>
      <div className="button_division" onClick={handleClickNext}>
        {">"}
      </div>
      <div className="button_division_navigate" onClick={handleLastPage}>
        {">>"}
      </div>
    </div>
  );
};

export default LorryPagination;
