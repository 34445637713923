import React, { useEffect } from "react";
import "../../styles/components/table/Table.css";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllDriverSuplllierUsers,
  fetchAllLogisticUsers,
  fetchAllParivaarUsers,
  fetchAllSurakshaUsers,
  selectAllUsers,
  selectloading,
} from "../../store/getUserData/allUserData";
import {
  decrementPage,
  incrementPage,
  selectCurrentPage,
  selectSelectedNavItem,
  selectTotalPages,
  setCurrentPage,
  setUniqueCode,
} from "../../store/selectCategory/selectCategory";
import Loader from "../Loader/Loader";
import { useNavigate } from "react-router-dom";
const Table = () => {
  const navigate = useNavigate();
  const allUsers = useSelector(selectAllUsers);
  const selectedNavItem = useSelector(selectSelectedNavItem);
  const totalPages = useSelector(selectTotalPages);
  const loading = useSelector(selectloading);
  const currentPage = useSelector(selectCurrentPage);
  const dispatch = useDispatch();

  // Fetch data when currentPage changes
  useEffect(() => {
    if (selectedNavItem === "suraksha") {
      dispatch(fetchAllSurakshaUsers());
    }
    if (selectedNavItem === "parivaar") {
      dispatch(fetchAllParivaarUsers());
    }
    if (selectedNavItem === "lorryBroker") {
      dispatch(fetchAllDriverSuplllierUsers());
    }
    if (
      selectedNavItem === "trader" ||
      selectedNavItem === "manufacturer" ||
      selectedNavItem === "transportCompany"
    ) {
      dispatch(fetchAllLogisticUsers());
    }
  }, [currentPage, selectedNavItem]);

  // Handle view user details
  const handleViewUser = (firstName, uniquecode) => {
    navigate(`/user/${firstName?.toLowerCase()}/${uniquecode?.toLowerCase()}}`);
    dispatch(setUniqueCode(uniquecode));
  };

  return (
    <>
      {loading && <Loader />}
      <div className="loadTable">
        <div className="tableHead">
          <td className="sno">S. No</td>
          <td className="tdate">Created Date</td>
          <td className="companyName">Full Name</td>
          <td className="uniqueId">User ID</td>
          <td className="companyName">Mobile No.</td>
          {selectedNavItem !== "parivaar" && selectedNavItem !== "suraksha" && (
            <td className="originState">State</td>
          )}
          <td className="tview">Application</td>
        </div>
        {allUsers?.map((item, index) => (
          <div
            key={index}
            className="tableHead tableBody"
            style={{ background: index % 2 === 1 ? "#ECF2FA" : "" }}
          >
            <td className="sno">{index + 1}</td>
            <td className="tdate">{item?.createdAt}</td>
            <td className="companyName">
              {item?.firstName} {item?.lastName}
            </td>
            <td className="uniqueId">{item?.uniquecode}</td>
            <td className="companyName">{item?.number}</td>
            {selectedNavItem !== "parivaar" &&
              selectedNavItem !== "suraksha" && (
                <td className="originState">{item?.state}</td>
              )}
            <td
              className="tview tviewbody"
              onClick={() => handleViewUser(item?.firstName, item?.uniquecode)}
            >
              View
            </td>
          </div>
        ))}

        <div className="pageCounts">
          <span
            className="firstPage"
            onClick={() => {
              dispatch(setCurrentPage(1));
            }}
          >
            &lt;&lt;
          </span>

          <span
            className="nextprevPage"
            onClick={() => {
              dispatch(decrementPage());
            }}
          >
            &lt;
          </span>

          {Array.from({ length: totalPages }, (_, i) => (
            <span
              key={i}
              className={
                currentPage === i + 1 ? "pageNumber currentPage" : "pageNumber"
              }
              onClick={() => {
                dispatch(setCurrentPage(i + 1));
              }}
            >
              {i + 1}
            </span>
          ))}

          <span
            className="nextprevPage"
            onClick={() => {
              dispatch(incrementPage());
            }}
          >
            &gt;
          </span>

          <span
            className="firstPage"
            onClick={() => {
              dispatch(setCurrentPage(totalPages));
            }}
          >
            &gt;&gt;
          </span>
        </div>
      </div>
    </>
  );
};

export default Table;
