
import React from "react";
import "../../styles/components/navbar/Navbar.css";
import riyo from "../../Assets/riyo.png"
import { MdLogout } from "react-icons/md";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../store/logout/logout";
import { useNavigate } from 'react-router-dom';


const Navbar = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate();
  // logout user 
  const handleLogout = () => {
    dispatch(logoutUser())
    navigate('/');
  }

  return (
    <div className="top-navbar">
      <div className="headerImg"><img src={riyo} alt="" /></div>
      <div className="logoutIcon" onClick={handleLogout}><MdLogout className="logoutIcon" /></div>
    </div>
  );
};

export default Navbar;
