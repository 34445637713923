import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./Constents/constants.css";
import store from "./store/store";
import { Provider } from "react-redux";
import { setSelectedNavItem, } from "./store/selectCategory/selectCategory";


// Retrieve values from local storage
const selectedNavItem = localStorage.getItem('selectedNavItem');


// Dispatch actions to set values in the Redux store
if(selectedNavItem){
    store.dispatch(setSelectedNavItem(selectedNavItem));
}






const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
