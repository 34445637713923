import React from "react";
import "../../styles/components/user/ImagePopUp.css";
import { RxCross2 } from "react-icons/rx";

function ImagePopUp({ onClose, img }) {
  return (
    <div className="userPopUp">
      <div className="userPopUpCross">
        <p onClick={onClose}>
          <RxCross2 />
        </p>
      </div>
      <div
        className="imageDiv"
        style={{
          display: "flex",
          justifyContent: img?.length === 1 ? "center" : "space-between",
          flexDirection: img?.length !== 1 ? "column" : "row",
        }}
      >
        {Array.isArray(img) && img.length > 0 ? (
          img.map((imageSrc, index) => (
            <a
              key={index}
              href={imageSrc}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={imageSrc}
                className="mappedImg"
                alt={`document ${index}`}
                style={{
                  width: img.length === 1 ? "100%" : "100%",
                  cursor: "pointer",
                }}
              />
            </a>
          ))
        ) : (
          <a href={img} target="_blank" rel="noopener noreferrer">
            <img
              src={img}
              alt="document"
              style={{ width: "100%", cursor: "pointer" }}
            />
          </a>
        )}
      </div>
    </div>
  );
}

export default ImagePopUp;
