import React, { useState } from 'react'
import '../../styles/components/ChangeRouteOfLorryBroker/ChangeRoute.css'
import { useGetChangeRouteDataQuery } from '../../store/GetChangeRouteLorryBrokerData/ChangeRouteData'
import ChangeRouteSelectDataPopUp from './ChangeRouteSelectDataPopUp'
import Loader from '../Loader/Loader'

const ChangeRoute = () => {
  const [search, setSearch] = useState('')

  const [num, setNum] = useState("")
  const [showPopUp, setShowPopUp] = useState(false)

  const { data, isLoading, } = useGetChangeRouteDataQuery(num)


  const searchData = () => {
    setNum(search)
  }

  if (isLoading) {
    return (
      <Loader />
    )
  }
  return (
    <>
      <div className='ChangeRoute_searchBox'>
        <input type="text" placeholder='Search Mobile Number' value={search} onChange={(e) => setSearch(e.target.value)} />
        <button onClick={() => searchData()}>Search</button>
      </div>
      {
        data ? <div className='ChangeRoute_main_conainer'>

          <div className="Changeroute_scroll_box">

            <div className='ChangeRoute_lorry_details_line_box'>
              <div className="ChangeRoute_lorry_details_line_box1"></div>
              <p>Routes</p>
              <div className="ChangeRoute_lorry_details_line_box2"></div>
            </div>
            <div className='ChangeRoute_details'>
              {
                data?.data?.stateanddistrict.map((data, i) => {
                  return (
                    <div className='ChangeRoute_data'>
                      <p> {i + 1} <span> State : </span> {data.state}</p>
                      <p><span> District : </span>{data.district + " "}</p>
                    </div>
                  )
                })
              }
            </div>
            {/* center line */}
            <div className='ChangeRoute_lorry_details_line_box'>
              <div className="ChangeRoute_lorry_details_line_box1"></div>
              <p>Lorry Details</p>
              <div className="ChangeRoute_lorry_details_line_box2"></div>
            </div>

            <div className='ChangeRoute_lorry_details'>
              <p><span>Body Types : </span>{data?.data?.body.map((data) => (data + " , "))} {" "}</p>
              <p><span>Truck Sizes : </span> {data?.data?.length.map((data) => (data + " , "))} {" "}</p>
              <p><span>No. of Wheels : </span> {data?.data?.wheel.map((data) => (data + ","))} {" "}</p>
              <p><span>No. of Axle : </span>{data?.data?.axle?.map((data) => (data + "  "))} {" "}</p>
            </div>
            <div className='ChangeRoute_lorry_details_line_box'>
              <div className="ChangeRoute_lorry_details_line_box1"></div>
              <p>Working Mode</p>
              <div className="ChangeRoute_lorry_details_line_box2"></div>
            </div>

            <div className='ChangeRoute_lorry_working_details'>
              <p><span>Working From : </span>{data?.data?.workingfrom}</p>
              <p>
                {data?.data?.gstno ? (
                  <>
                    <span>GST No. : </span>
                    {data.data.gstno}
                  </>
                ) : null}
              </p>
            </div>
          </div>
          <button className='changeButton' onClick={() => setShowPopUp(true)}>Change</button>
        </div> : <p></p>
      }

      {
        showPopUp ? <ChangeRouteSelectDataPopUp onclose={() => setShowPopUp(false)} num={search} /> : null
      }
    </>
  )
}

export default ChangeRoute
