import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import pkg from "../../../package.json";
import { getCookie } from "../Cookies";
const LorryAPIData = createApi({
  reducerPath: "LorryAPIData",

  baseQuery: fetchBaseQuery({
    baseUrl: pkg.baseUrl,
    prepareHeaders: (headers) => {
      const token = getCookie("authToken");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Truck"],
  endpoints: (builder) => ({
    getTruckData: builder.query({
      query: ({ page }) => `/adminpanel/all-trucks?page=${page}&limit=12`,
      method: "GET",
      providesTags: ["Truck"],
    }),
    updateApiStatus: builder.mutation({
      query: (id) => ({
        url: `/adminpanel/approve-truck/${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Truck"],
    }),
  }),
});

export const { useGetTruckDataQuery, useUpdateApiStatusMutation } =
  LorryAPIData;
export default LorryAPIData;
