import React from 'react'
import ChangeRoute from '../components/ChangeRouteOfLorryBroker/ChangeRoute'

const ChangeRouteOfLorryBroker = () => {
  return (
    <>
      <ChangeRoute />
    </>
  )
}

export default ChangeRouteOfLorryBroker
