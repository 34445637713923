import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";

// Define the initial state
const initialState = {};

// Create a slice for logout
const logoutSlice = createSlice({
  name: "logout",
  initialState,
  reducers: {
    // Action to perform logout
    logoutUser: () => {
      // Clear token from local storage
      localStorage.clear();
      Cookies.remove("authToken");
      // Remove token from axios header
      delete axios.defaults.headers.common["Authorization"];
    },
  },
});

// Export the action and reducer
export const { logoutUser } = logoutSlice.actions;
export default logoutSlice.reducer;
