import React from 'react';
import "../../styles/components/loader/Loader.css";

// Loader component to display a loading spinner
const Loader = () => {
    return (
        <div className="loading-overlay">
            {/* Spinner icon */}
            <i className="fa fa-spinner fa-spin" style={{ fontSize: "48px", color: "var(--primaryColor)" }}></i>
        </div>
    );
}

export default Loader;