import { createSlice } from "@reduxjs/toolkit";

// Define the initial state
const initialState = {
  selectedNavItem: "trader",
  loads: "postload",
  currentPage: 1, // Initial currentPage value
  totalPages: 1, // Initial load value 15
  searchTerm: "", // Initial search term
  uniqueCode: localStorage.getItem("uniqueCode")
    ? localStorage.getItem("uniqueCode")
    : "",
  number: "",
};

// Create a slice for selected nav item
const selectedNavItemSlice = createSlice({
  name: "selectedNavItem",
  initialState,
  reducers: {
    // Action to set the selected nav item
    setSelectedNavItem: (state, action) => {
      state.selectedNavItem = action.payload;
      localStorage.setItem("selectedNavItem", action.payload);
    },
    // Action to set the selected load type
    setLoads: (state, action) => {
      state.loads = action.payload;
      localStorage.setItem("loads", JSON.stringify(action.payload));
    },
    // Action to set the current Page
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },

    // Action to set the current pages count
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    // Action to increment the current Page
    incrementPage: (state) => {
      if (state.currentPage < state.totalPages) {
        state.currentPage += 1;
      }
    },
    // Action to decrement the current Page
    decrementPage: (state) => {
      if (state.currentPage > 1) {
        state.currentPage -= 1;
      }
    },

    // Action to set the search term
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },

    // action to set unique code
    setUniqueCode: (state, action) => {
      state.uniqueCode = action.payload;
      localStorage.setItem("uniqueCode", action.payload);
    },

    // action to set phone number
    setNumber: (state, action) => {
      state.number = action.payload;
    },
  },
});

// Export the actions and reducer
export const {
  setSelectedNavItem,
  setLoads,
  setCurrentPage,
  setTotalPages,
  incrementPage,
  decrementPage,
  setSearchTerm,
  setUniqueCode,
  setNumber,
} = selectedNavItemSlice.actions;
export default selectedNavItemSlice.reducer;

// Define selectors to get the selected nav item, selected load type, current page, total postload count, total activeload count, total completed count, and total cancelled count from the state
export const selectSelectedNavItem = (state) =>
  state?.selectCat?.selectedNavItem;
export const selectLoads = (state) => state?.selectCat?.loads;
export const selectTotalPages = (state) => state?.selectCat?.totalPages;
export const selectCurrentPage = (state) => state?.selectCat?.currentPage;
export const selectSearchTerm = (state) => state?.selectCat?.searchTerm;
export const selectUniqueCode = (state) => state?.selectCat?.uniqueCode;
export const selectNumber = (state) => state?.selectCat?.number;
