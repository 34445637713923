import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getCookie } from "../Cookies";
import axios from "axios";
import {
    getAllParivaarUser,
    getAllSurakshaUser,
    getAllDriverSupplierUser,
    getAllLogisticUser,
    getSingleUserParivaar,
    getSingleUserSuraksha,
    getSingleUserDriverSupplier,
    getSingleUserLogistic,
    paymentByPassSuraksha,
    paymentByPassDriverSupplier,
    paymentByPassLogistic,
    deleteAllUserApi,
    kycBlockSuraksha,
    kycBlockDriverSupplier,
    kycBlockParivaar,
    kycBlockLogistic,
} from "../../Constents/apis";
import {
    selectCurrentPage,
    selectSearchTerm,
    selectSelectedNavItem,
    setTotalPages,
    selectUniqueCode,
    selectNumber,
} from "../selectCategory/selectCategory";
import { toast } from "react-toastify";

// Define the async thunk
export const fetchAllParivaarUsers = createAsyncThunk(
    "allParivaarUsers/fetchAllParivaarUsers",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const page = selectCurrentPage(state);
            const search = selectSearchTerm(state);
            const limit = 15;
            const token = getCookie("authToken");
            const response = await axios.get(getAllParivaarUser, {
                params: {
                    limit,
                    page,
                    search,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            thunkAPI.dispatch(setTotalPages(response?.data?.totalPages));
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Define the async thunk for Suraksha users
export const fetchAllSurakshaUsers = createAsyncThunk(
    "allSurakshaUsers/fetchAllSurakshaUsers",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const page = selectCurrentPage(state);
            const search = selectSearchTerm(state);
            const limit = 15;
            const token = getCookie("authToken");

            const response = await axios.get(getAllSurakshaUser, {
                params: {
                    limit,
                    page,
                    search,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            thunkAPI.dispatch(setTotalPages(response?.data?.totalPages));
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Define the async thunk for Suraksha users
export const fetchAllDriverSuplllierUsers = createAsyncThunk(
    "allSurakshaUsers/fetchAllDriverSupplierUsers",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const page = selectCurrentPage(state);
            const search = selectSearchTerm(state);
            const limit = 15;
            const token = getCookie("authToken");

            const response = await axios.get(
                getAllDriverSupplierUser,
                {
                    params: {
                        limit,
                        page,
                        search,
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            thunkAPI.dispatch(setTotalPages(response?.data?.totalPages));
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// Define the async thunk for Suraksha users
export const fetchAllLogisticUsers = createAsyncThunk(
    "allSurakshaUsers/fetchAllLogisticUsers",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const userType = selectSelectedNavItem(state);
            const page = selectCurrentPage(state);
            const search = selectSearchTerm(state);
            const limit = 15;
            const token = getCookie("authToken");

            const response = await axios.get(
                `${getAllLogisticUser}/${userType}`,
                {
                    params: {
                        limit,
                        page,
                        search,
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            thunkAPI.dispatch(setTotalPages(response?.data?.totalPages));
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

//  get single logistic user
export const fetchSingleUserLogistic = createAsyncThunk(
    "allSurakshaUsers/fetchSingleUserLogistic",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const uniqueId = selectUniqueCode(state);
            const token = getCookie("authToken");

            const response = await axios.get(
                `${getSingleUserLogistic}/${uniqueId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

//  get single Suraksha user
export const fetchSingleUserSuraksha = createAsyncThunk(
    "allSurakshaUsers/fetchSingleUserSuraksha",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const uniqueId = selectUniqueCode(state);
            const token = getCookie("authToken");

            const response = await axios.get(
                `${getSingleUserSuraksha}/${uniqueId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

//  get single DriverSupplier  user
export const fetchSingleUserDriverSupplier = createAsyncThunk(
    "allSurakshaUsers/fetchSingleUserDriverSupplier",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const uniqueId = selectUniqueCode(state);
            const token = getCookie("authToken");

            const response = await axios.get(
                `${getSingleUserDriverSupplier}/${uniqueId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

//  get single Parivaar  user
export const fetchSingleUserParivaar = createAsyncThunk(
    "allSurakshaUsers/fetchSingleUserParivaar",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const uniqueId = selectUniqueCode(state);
            const token = getCookie("authToken");

            const response = await axios.get(
                `${getSingleUserParivaar}/${uniqueId}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

// payment by pass for Suraksha
export const paymentByPassSurakshaApi = createAsyncThunk(
    "allSurakshaUsers/paymentByPassSurakshaApi",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const number = selectNumber(state);
            const token = getCookie("authToken");

            const response = await axios.put(
                `${paymentByPassSuraksha}/${number}`,{},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data.status === "success") {
                toast.success("Payment bypass successful!");
                thunkAPI.dispatch(fetchSingleUserSuraksha());
            }
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data || "An error occurred";
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

// payment bypasss for driver supplier
export const paymentByPassDriverSupplierApi = createAsyncThunk(
    "allSurakshaUsers/paymentByPassDriverSupplierApi",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const number = selectNumber(state);
            const token = getCookie("authToken");

            const response = await axios.put(
                `${paymentByPassDriverSupplier}/${number}`,{},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data.status === "success") {
                toast.success("Payment bypass successful!");
                thunkAPI.dispatch(fetchSingleUserDriverSupplier());
            }
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data || "An error occurred";
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

// payment bypass for logistic
export const paymentByPassDriverLogisticApi = createAsyncThunk(
    "allSurakshaUsers/paymentByPassDriverLogisticApi",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const number = selectNumber(state);
            const token = getCookie("authToken");
            const response = await axios.put(
                `${paymentByPassLogistic}/${number}`,{},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.data.status === "success") {
                toast.success("Payment bypass successful!");
                thunkAPI.dispatch(fetchSingleUserLogistic());
            }
            return response.data;
        } catch (error) {
            const errorMessage = error.response?.data || "An error occurred";
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

// block kyc for Suraksha
export const kycBlockSurakshaApi = createAsyncThunk(
    "allSurakshaUsers/kycBlockSurakshaApi",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const number = selectNumber(state);
            const token = getCookie("authToken");

            const response = await axios.put(`${kycBlockSuraksha}/${number}`,{}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.data?.status === "success") {
                toast.success("Kyc Bypass success!");
                thunkAPI.dispatch(fetchSingleUserSuraksha());
            }
            return response.data;
        } catch (error) {
            const errorMessage = error.response.data || "Server Error";
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

// block kyc for DriverSUpplier
export const kycBlockDriverSupplierApi = createAsyncThunk(
    "allSurakshaUsers/kycBlockDriverSupplierApi",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const number = selectNumber(state);
            const token = getCookie("authToken");

            const response = await axios.put(
                `${kycBlockDriverSupplier}/${number}`,{},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response?.data?.status === "success") {
                toast.success("Kyc Bypass success!");
                thunkAPI.dispatch(fetchSingleUserDriverSupplier());
            }
            return response.data;
        } catch (error) {
            const errorMessage = error.response.data || "Server Error";
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

// block kyc for Parivaar
export const kycBlockParivaarApi = createAsyncThunk(
    "allSurakshaUsers/kycBlockDriverParivaarApi",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const number = selectNumber(state);
            const token = getCookie("authToken");

            const response = await axios.put(`${kycBlockParivaar}/${number}`, {},{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response?.data?.status === "success") {
                toast.success("Kyc Bypass success!");
                thunkAPI.dispatch(fetchSingleUserParivaar());
            }
            return response.data;
        } catch (error) {
            const errorMessage = error.response.data || "Server Error";
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

// block kyc for logistic
export const kycBlockLogisticApi = createAsyncThunk(
    "allSurakshaUsers/kycBlockDriverlogisticApi",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const number = selectNumber(state);
            const token = getCookie("authToken");

            const response = await axios.put(`${kycBlockLogistic}/${number}`, {},{
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response?.data?.status === "success") {
                toast.success("Kyc Bypass success!");
                thunkAPI.dispatch(fetchSingleUserLogistic());
            }
            return response.data;
        } catch (error) {
            const errorMessage = error.response.data || "Server Error";
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

// Block all user thunk
export const blockUserAPiBussiness = createAsyncThunk(
    "allSurakshaUsers/deleteUserAPi",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const uniqueCode = selectUniqueCode(state);
            const token = getCookie("authToken");

            const response = await axios.post(
                `${deleteAllUserApi}/${uniqueCode}`,{},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response?.data?.status === "success") {
                toast.success("Block user successful!");
                thunkAPI.dispatch(fetchSingleUserDriverSupplier());
            }
            return response?.data;
        } catch (error) {
            const errorMessage = error.response.data || "server error!";
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const blockUserAPiParivaar = createAsyncThunk(
    "allSurakshaUsers/deleteUserAPi",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const uniqueCode = selectUniqueCode(state);
            const token = getCookie("authToken");

            const response = await axios.post(
                `${deleteAllUserApi}/${uniqueCode}`,{},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response?.data?.status === "success") {
                toast.success("Block user successful!");
                thunkAPI.dispatch(fetchSingleUserParivaar());
            }
            return response?.data;
        } catch (error) {
            const errorMessage = error.response.data || "server error!";
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const blockUserAPiLogistic = createAsyncThunk(
    "allSurakshaUsers/deleteUserAPi",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const uniqueCode = selectUniqueCode(state);
            const token = getCookie("authToken");

            const response = await axios.post(
                `${deleteAllUserApi}/${uniqueCode}`,{},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response?.data?.status === "success") {
                toast.success("Block user successful!");
                thunkAPI.dispatch(fetchSingleUserLogistic());
            }
            return response?.data;
        } catch (error) {
            const errorMessage = error.response.data || "server error!";
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

export const blockUserAPiSurksha = createAsyncThunk(
    "allSurakshaUsers/deleteUserAPi",
    async (_, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            const uniqueCode = selectUniqueCode(state);
            const token = getCookie("authToken");

            const response = await axios.post(
                `${deleteAllUserApi}/${uniqueCode}`,{},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response?.data?.status === "success") {
                toast.success("Block user successful!");
                thunkAPI.dispatch(fetchSingleUserSuraksha());
            }
            return response?.data;
        } catch (error) {
            const errorMessage = error.response.data || "server error!";
            return thunkAPI.rejectWithValue(errorMessage);
        }
    }
);

const allParivaarUsersSlice = createSlice({
    name: "allParivaarUsers",
    initialState: {
        users: [],
        singleUser: [],
        totalUsers: 0,
        currentPage: 1,
        loading: "idle",
        error: null,
    },
    reducers: {
        clearSingleUser: (state, action) => {
            state.singleUser = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllParivaarUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllParivaarUsers.fulfilled, (state, action) => {
                state.users = action.payload.allUsers;
                state.totalUsers = action.payload.totalUsers;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
                state.loading = false;
            })
            .addCase(fetchAllParivaarUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // suraksha users
            .addCase(fetchAllSurakshaUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllSurakshaUsers.fulfilled, (state, action) => {
                state.users = action.payload.allUsers;
                state.totalUsers = action.payload.totalUsers;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
                state.loading = false;
            })
            .addCase(fetchAllSurakshaUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // lorry borker users
            .addCase(fetchAllDriverSuplllierUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(
                fetchAllDriverSuplllierUsers.fulfilled,
                (state, action) => {
                    state.users = action.payload.allUsers;
                    state.totalUsers = action.payload.totalUsers;
                    state.currentPage = action.payload.currentPage;
                    state.totalPages = action.payload.totalPages;
                    state.loading = false;
                }
            )
            .addCase(fetchAllDriverSuplllierUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // lorry logistics users
            .addCase(fetchAllLogisticUsers.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAllLogisticUsers.fulfilled, (state, action) => {
                state.users = action.payload.allUsers;
                state.totalUsers = action.payload.totalUsers;
                state.currentPage = action.payload.currentPage;
                state.totalPages = action.payload.totalPages;
                state.loading = false;
            })
            .addCase(fetchAllLogisticUsers.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // fetch single user logistic
            .addCase(fetchSingleUserLogistic.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSingleUserLogistic.fulfilled, (state, action) => {
                state.singleUser = action.payload;
                state.loading = false;
            })
            .addCase(fetchSingleUserLogistic.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // fetch single user Suraksha
            .addCase(fetchSingleUserSuraksha.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSingleUserSuraksha.fulfilled, (state, action) => {
                state.singleUser = action.payload;
                state.loading = false;
            })
            .addCase(fetchSingleUserSuraksha.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })

            // fetch single user Driver supplier
            .addCase(fetchSingleUserDriverSupplier.pending, (state) => {
                state.loading = true;
            })
            .addCase(
                fetchSingleUserDriverSupplier.fulfilled,
                (state, action) => {
                    state.singleUser = action.payload;
                    state.loading = false;
                }
            )
            .addCase(
                fetchSingleUserDriverSupplier.rejected,
                (state, action) => {
                    state.loading = false;
                    state.error = action.payload;
                }
            )

            // fetch single user Parivaar
            .addCase(fetchSingleUserParivaar.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchSingleUserParivaar.fulfilled, (state, action) => {
                state.singleUser = action.payload;
                state.loading = false;
            })
            .addCase(fetchSingleUserParivaar.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // paymentByPassSurakshaApi
            .addCase(paymentByPassSurakshaApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(paymentByPassSurakshaApi.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(paymentByPassSurakshaApi.rejected, (state) => {
                state.loading = false;
            })

            // paymentByPassDriverSupplierApi
            .addCase(paymentByPassDriverSupplierApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(paymentByPassDriverSupplierApi.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(paymentByPassDriverSupplierApi.rejected, (state) => {
                state.loading = false;
            })
            // paymentByPassDriverLogisticApi
            .addCase(paymentByPassDriverLogisticApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(paymentByPassDriverLogisticApi.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(paymentByPassDriverLogisticApi.rejected, (state) => {
                state.loading = false;
            })
            // kycBlockSurakshaApi
            .addCase(kycBlockSurakshaApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(kycBlockSurakshaApi.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(kycBlockSurakshaApi.rejected, (state) => {
                state.loading = false;
            })
            // kycBlockDriverSupplierApi
            .addCase(kycBlockDriverSupplierApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(kycBlockDriverSupplierApi.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(kycBlockDriverSupplierApi.rejected, (state) => {
                state.loading = false;
            })
            // kycBlockParivaarApi
            .addCase(kycBlockParivaarApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(kycBlockParivaarApi.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(kycBlockParivaarApi.rejected, (state) => {
                state.loading = false;
            })

            // kycBlockLogisticApi
            .addCase(kycBlockLogisticApi.pending, (state) => {
                state.loading = true;
            })
            .addCase(kycBlockLogisticApi.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(kycBlockLogisticApi.rejected, (state) => {
                state.loading = false;
            });
    },
});
export const { clearSingleUser } = allParivaarUsersSlice.actions;
export default allParivaarUsersSlice.reducer;

export const selectAllUsers = (state) => state?.allUsers?.users;
export const singleUser = (state) => state?.allUsers.singleUser;
export const selectloading = (state) => state?.allUsers?.loading;
