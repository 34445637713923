import pkg from "../../package.json";

export const fetchAdminApi = `${pkg.baseUrl}`;
export const loginAdmin = `${pkg.baseUrl}/adminpanel/loginadmin`;
export const getAllParivaarUser = `${pkg.baseUrl}/adminpanel/all-parivaar-users`;
export const getAllSurakshaUser = `${pkg.baseUrl}/adminpanel/all-suraksha-users`;
export const getAllDriverSupplierUser = `${pkg.baseUrl}/adminpanel/all-driver-suplier-users`;
export const getAllLogisticUser = `${pkg.baseUrl}/adminpanel/all-logistics-users`;

// get single user
export const getSingleUserParivaar = `${pkg.baseUrl}/adminpanel/getsingelparivaaruser`;
export const getSingleUserSuraksha = `${pkg.baseUrl}/adminpanel/getsingelsurakshauser`;
export const getSingleUserDriverSupplier = `${pkg.baseUrl}/adminpanel/getsingeldriversuplieruser`;
export const getSingleUserLogistic = `${pkg.baseUrl}/adminpanel/getsingeluserlogistics`;

// payment bypass

// export const paymentByPassParivaar = `${pkg.baseUrl}/adminpanel/paymentbypasssurakshauser`
export const paymentByPassSuraksha = `${pkg.baseUrl}/adminpanel/paymentbypasssurakshauser`;
export const paymentByPassDriverSupplier = `${pkg.baseUrl}/adminpanel/paymentbypassdriversuplieruser`;
export const paymentByPassLogistic = `${pkg.baseUrl}/adminpanel/paymentbypasslogisticsuser`;

// block kyc user

export const kycBlockSuraksha = `${pkg.baseUrl}/adminpanel/verificationbypasssurakshauser`;
export const kycBlockDriverSupplier = `${pkg.baseUrl}/adminpanel/verificationbypassbusinessuser`;
export const kycBlockLogistic = `${pkg.baseUrl}/adminpanel/verificationbypasslogisticsuser`;
export const kycBlockParivaar = `${pkg.baseUrl}/adminpanel/verificationbypassparivaar`;

// delete all user api
export const deleteAllUserApi = `${pkg.baseUrl}/adminpanel/blockalluser`;
// export const allTruck = `${pkg.baseUrl}/adminpanel/all-trucks`;
// export const approveTruck = `${pkg.baseUrl}/adminpanel/approve-truck/:id`;
