import { useEffect, useRef } from "react";
import "../../styles/components/lorry/ViewApplicationPopUp.css";
import { RxCross2 } from "react-icons/rx";

const detail = [
  {
    img: "https://cdn.pixabay.com/photo/2024/08/19/10/51/giant-8980463_1280.jpg",
    company: "TechCode",
    name: "Suraj Yadav",

    id: "688996",
    Mobile: "9795551336",
    Email: "suraj@123moveexa.com",
  },
];

const ViewApplicationPopUp = ({ handleCloseView, viewDetails }) => {
  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleCloseView();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleCloseView]);

  return (
    <div className="ViewApplicationPopUp_container" ref={popupRef}>
      <div className="cross_view_icon">
        <RxCross2 className="rxcross2_icon" onClick={handleCloseView} />
      </div>

      {viewDetails?.map((item, index) => (
        <div key={index} className="details_user_view">
          <div className="viewImg_div">
            <img src={item.profileImg} alt="xyz" className="ImageProfileView" />
          </div>
          <div className="details_user_view_data">
            <div>
              <span>Name :</span>
              <span>
                {" "}
                {item.firstName}
                {item.lastName}
              </span>
            </div>

            <div>
              <span>Email Id :</span>
              <span> {item.email}</span>
            </div>
            <div>
              <span>Mobile No. :</span>
              <span> {item.number}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ViewApplicationPopUp;
