import React from "react";
import "../../styles/components/navbar/SideNavbar.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSelectedNavItem,
  setCurrentPage,
  setSearchTerm,
  setSelectedNavItem,
} from "../../store/selectCategory/selectCategory";
import {
  clearSingleUser,
  fetchAllDriverSuplllierUsers,
  fetchAllLogisticUsers,
  fetchAllParivaarUsers,
  fetchAllSurakshaUsers,
} from "../../store/getUserData/allUserData";

const navitems = [
  {
    name: "TRADER",
    value: "trader",
  },
  {
    name: "MANUFACTURER",
    value: "manufacturer",
  },
  {
    name: "TRANSPORT COMPANY",
    value: "transportCompany",
  },
  {
    name: "LORRY BROKER",
    value: "lorryBroker",
  },
  {
    name: "SURAKSHA",
    value: "suraksha",
  },
  {
    name: "PARIVAAR",
    value: "parivaar",
  },
  {
    name: " SURAKSHA LORRY",
    value: "lorry",
  },
  {
    name: "CHANGE ROUTE OF LORRY BROKER",
    value: "ChangeRouteOfLorryBroker",
  },
];
const SideNavbar = () => {
  const selectedNav = useSelector(selectSelectedNavItem);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavClick = (item) => {
    navigate(`/${item.value}`);
    dispatch(setSelectedNavItem(item.value));
    dispatch(setCurrentPage(1));
    dispatch(setSearchTerm(""));
    dispatch(clearSingleUser());
    if (item?.value === "parivaar") {
      dispatch(fetchAllParivaarUsers());
    } else if (item?.value === "suraksha") {
      dispatch(fetchAllSurakshaUsers());
    } else if (item?.value === "lorryBroker") {
      dispatch(fetchAllDriverSuplllierUsers());
    } else {
      dispatch(fetchAllLogisticUsers());
    }
  };

  return (
    <div className="side-navbar">
      <div className="profileImgBox">
        <img
          src="https://res.cloudinary.com/daahrquhn/image/upload/v1723817131/oy0rlzudcqzmdekwcerw.jpg"
          alt=""
        />
      </div>
      <p>
        Name: <strong>RIYO ADMIN</strong>
      </p>
      <div className="sideNavBox">
        {navitems.map((item, index) => (
          <div
            key={index}
            style={{
              background: selectedNav === item.value ? "#0D61D3" : "",
              color: selectedNav === item.value ? "#fff" : "",
            }}
            onClick={() => {
              handleNavClick(item);
            }}
            className="sideNavItem"
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideNavbar;
