// src/store/store.js
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

// Import your API slices and reducers
import LorryAPIData from "./getLorryData/LorryDataSlice";
import loadsReducer from "./selectCategory/selectCategory";
import logoutReducer from "./logout/logout";
import allUserReducer from "./getUserData/allUserData";
import ChangeRouteData from "../store/GetChangeRouteLorryBrokerData/ChangeRouteData";

const store = configureStore({
  reducer: {
    selectCat: loadsReducer,
    logout: logoutReducer,
    allUsers: allUserReducer,
    [LorryAPIData.reducerPath]: LorryAPIData.reducer,
    [ChangeRouteData.reducerPath]: ChangeRouteData.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(LorryAPIData.middleware)
      .concat(ChangeRouteData.middleware), 
});

setupListeners(store.dispatch);

export default store;
