import React from "react";
import "../../styles/components/user/ImagePopUp.css";
import { RxCross2 } from "react-icons/rx";

function ConfirmPopUp({ onClose, functionCall }) {
  return (
    <div className="confirmPopUp">
      <div className="userPopUpCross">
        <p onClick={onClose}>
          <RxCross2 />
        </p>
      </div>
      <h2>Are You Sure</h2>
      <div className="confirmPopUpbutton">
        <button onClick={functionCall} className="confirmPopUpbuttonChild">
          Yes
        </button>
        <button onClick={onClose} className="confirmPopUpbuttonChild">
          No
        </button>
      </div>
    </div>
  );
}

export default ConfirmPopUp;
