import React, { useState } from 'react'
import "../../styles/components/stats/Stats.css"
import { useDispatch, useSelector } from 'react-redux'
import { selectLoads, selectSelectedNavItem, setSearchTerm } from '../../store/selectCategory/selectCategory'
import { fetchAllDriverSuplllierUsers, fetchAllParivaarUsers, fetchAllSurakshaUsers } from '../../store/getUserData/allUserData'

const Stats = () => {
    const [searchInput, setSearchInput] = useState('');
    const dispatch = useDispatch()
    const selectedNavItem = useSelector(selectSelectedNavItem)


    // handle the search term
    const handleSearch = () => {
        dispatch(setSearchTerm(searchInput));
        if (selectedNavItem === "suraksha") {
            dispatch(fetchAllSurakshaUsers())
        }
        if (selectedNavItem === "parivaar") {
            dispatch(fetchAllParivaarUsers())
        }
        if (selectedNavItem === "lorryBroker") {
            dispatch(fetchAllDriverSuplllierUsers())
        }
    };
    return (
        <div className='statDetails'>
            <div className='detailSearch'>
                <div className='searchbox'>
                    <input type="text" placeholder='Search by Unique Id/Name' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
                    <button onClick={handleSearch}>Search</button>
                </div>
            </div>
        </div>
    )
}

export default Stats
