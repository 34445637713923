import React, { useRef, useState } from "react";
import logo from "../../Assets/logo.svg";
import bg from "../../Assets/bg.svg";
import user from "../../Assets/user.svg";
import passwardicon from "../../Assets/passward.svg";
import text from "../../Assets/text.png";
import "../../styles/components/Login/LoginPage.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import base64 from "base-64";
import { loginAdmin } from "../../Constents/apis";

const LoginPage = () => {
  const email = useRef();
  const password = useRef();
  const userType = useRef();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email.current.value || !password.current.value) {
      alert("email or password should  not be empty");
      return;
    }
    setLoading(true);
    try {
      let data = {
        email: email.current.value,
        password: password.current.value,
      };

      const response = await axios.post(loginAdmin, data);

      const token = response?.data?.token;

      if (token) {
        Cookies.set("authToken", token);
        navigate("/trader");
      }
    } catch (error) {
      setLoading(false);
      alert("Invalid Credentials");
      console.log(error);
    }
  };

  return (
    <div className="Login-page">
      <div className="left">
        <img className="text" src={text} alt="" />
        <img className="bg" src={bg} alt="" />
      </div>
      <div className="right">
        <form className="login-form" onSubmit={handleLogin}>
          <img className="logo" src={logo} alt="" />
          <div className="welcome">Welcome!</div>

          <div className="input-field">
            <img className="icon" src={user} alt="" />
            <input type="text" placeholder="Email" ref={email} />
          </div>
          <div className="input-field">
            <img className="icon" src={passwardicon} alt="" />
            <input type="password" placeholder="Password" ref={password} />
          </div>
          <button type="submit">Login</button>
          {loading && (
            <div className="loading-overlay">
              <i
                className="fa fa-spinner fa-spin"
                style={{ fontSize: "48px", color: "var(--primary)" }}
              ></i>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default LoginPage;
