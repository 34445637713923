import React from "react";
import Stats from "../components/Stats/Stats";
import Table from "../components/Table/Table";

const LorryBroker = () => {
  return (
    <>
      <Stats />
      <Table />
      //
    </>
  );
};

export default LorryBroker;
